import React, { useEffect, useRef, useState } from "react"
import { Card, CardBody, Col, Collapse, Row, Table } from "reactstrap"
import readXlsxFile from "read-excel-file"
import chargeSample from "./GroomWellProduct.xlsx"
import { Tbody, Th, Tr } from "react-super-responsive-table"
import classnames from "classnames"
import CommonValidationTable from "./ComonValidataionTable"
import useApi from "common/ApiMiddlewere"
import { toast } from "react-hot-toast"

let size = []
let categories = []
let color = []
let products = []
let validationForUpload = []
let productvalidation = []
let sizevalidation = []
let colorvalidation = []
let categoryvalidation = []

function ImportProduct() {
  document.title = `Import Product | ${localStorage.getItem("ShopName")}`
  const { postApi } = useApi()
  const [validationError, setvalidationError] = useState({
    product: [],
    size: [],
    color: [],
    category: [],
  })

  useEffect(() => {
    productvalidation = []
    sizevalidation = []
    colorvalidation = []
    categoryvalidation = []
    size = []
    categories = []
    color = []
    products = []
    validationForUpload = []
  }, [])

  const inputRef = useRef(null)
  const exelFile = useRef(null)

  const [category, setcategory] = useState([])
  const [colorArr, setcolorArr] = useState([])
  const [sizeArr, setsizeArr] = useState([])
  const [productArr, setproductArr] = useState([])
  const [validation, setvalidation] = useState([])
  const [flagUpload, setflagUpload] = useState(false)

  const handleClick = () => {
    setcategory([])
    setcolorArr([])
    setsizeArr([])
    setproductArr([])
    // 👇️ open file input box on click of another element
    inputRef.current.click()
  }

  const handleDownloadExel = () => {
    setLoading(true)
    postApi("Product/export-products", null).then(({ data, status }) => {
      if (status === 200) {
        setLoading(false)
        var mediaType = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`

        var downloadLink = document.createElement("a");
        downloadLink.href = mediaType;
        var shopName = localStorage.getItem("ShopName");
        downloadLink.download = `${shopName}.xlsx`;//xlsx or csv
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else {
        setLoading(false)
        toast.error(data)
      }
    })
  }

  const [col1, setcol1] = useState(true)
  const [col2, setcol2] = useState(true)
  const [col3, setcol3] = useState(true)
  const [col4, setcol4] = useState(true)
  const [loading, setLoading] = useState(false)

  const handleImport = async $event => {
    setLoading(true)
    setvalidationError({
      product: [],
      size: [],
      color: [],
      category: [],
    })
    categories = []
    size = []
    color = []
    products = []
    validationForUpload = []
    productvalidation = []
    sizevalidation = []
    colorvalidation = []
    categoryvalidation = []

    const files = $event.target.files

    readXlsxFile(files[0]).then(rows => {
      for (let i = 1; i < rows.length; i++) {
        let obj = {
          SrNo: rows[i][0],
          Name: rows[i][1] ? rows[i][1]?.toString() : null,
          ErrorMessage: "",
        }
        if (obj.Name) {
          categories.push(obj)
        }
      }
    })

    readXlsxFile(files[0], { sheet: "Size" }).then(rows => {
      for (let i = 1; i < rows.length; i++) {
        let obj = {
          SrNo: rows[i][0],
          Name: rows[i][1] ? rows[i][1]?.toString() : null,
          ErrorMessage: "",
        }
        if (obj.Name) {
          size.push(obj)
        }
      }
    })

    readXlsxFile(files[0], { sheet: "Color" }).then(rows => {
      for (let i = 1; i < rows.length; i++) {
        let obj = {
          SrNo: rows[i][0],
          Name: rows[i][1] ? rows[i][1]?.toString() : null,
          ErrorMessage: "",
        }
        if (obj.Name) {
          color.push(obj)
        }
      }
    })

    readXlsxFile(files[0], { sheet: "Products" }).then(rows => {
      for (let i = 1; i < rows.length; i++) {
        if (rows[i][0] != null) {
          let obj = {
            SrNo: rows[i][0],
            Name: rows[i][1] ? rows[i][1]?.toString() : null,
            Rent: rows[i][2] || 0,
            CategoryName: rows[i][3] ? rows[i][3]?.toString() : null,
            SizeName: rows[i][4] ? rows[i][4]?.toString() : "",
            ColorName: rows[i][5] ? rows[i][5]?.toString() : "",
            Code: rows[i][6] ? rows[i][6]?.toString() : null,
            Qty: rows[i][7] ? rows[i][7] : 1,
            MRP: rows[i][8] || 0,
            SalePrice: rows[i][9] || 0,
            Remarks: rows[i][10] == null ? "" : rows[i][10],
            ErrorMessage: "",
          }
          products.push(obj)
        }
      }
    })

    setTimeout(() => {
      products.forEach(function (current) {
        if (current.Name == null) {
          current.Name = "--"
          current.ErrorMessage +=
            current.ErrorMessage == ""
              ? "The name is required."
              : " | The name is required."
        }
        if (current.Rent == null) {
          current.Rent = "--"
          current.ErrorMessage +=
            current.ErrorMessage == ""
              ? "The Rent is required."
              : " | The Rent is required."
        }

        if (current.Code == null) {
          current.Code = "--"
          current.ErrorMessage += "The code is required."
          current.ErrorMessage +=
            current.ErrorMessage == ""
              ? "The code is required."
              : " | The code is required."
        }

        let obj = products.filter(o => o.Code === current.Code);
        if (obj != undefined && obj.length > 1) {
          current.ErrorMessage +=
            current.ErrorMessage == ""
              ? "Code already exists."
              : " | Code already exists."
        }

      })

      let productError = products.filter(o => o.ErrorMessage !== "");


      // products?.reduce((acc, current, index, array) => {
      //   if (current.Name == null) {
      //     current.Name = "--"
      //     current.ErrorMessage +=
      //       current.ErrorMessage == ""
      //         ? "The name is required."
      //         : " | The name is required."
      //   }
      //   if (current.Rent == null) {
      //     current.Rent = "--"
      //     current.ErrorMessage +=
      //       current.ErrorMessage == ""
      //         ? "The Rent is required."
      //         : " | The Rent is required."
      //   }

      //   if (current.Code == null) {
      //     current.Code = "--"
      //     current.ErrorMessage += "The code is required."
      //     current.ErrorMessage +=
      //       current.ErrorMessage == ""
      //         ? "The code is required."
      //         : " | The code is required."
      //   }


      //   if (
      //     array.findIndex(item => item.Code === current.Code) !== index &&
      //     !acc.includes(current)
      //   ) {
      //     validationForUpload.push(current.SrNo)
      //     acc.push(current)
      //     current.ErrorMessage +=
      //       current.ErrorMessage == ""
      //         ? "Code already exists."
      //         : " | Code already exists."
      //   }
      //   if (current.ErrorMessage == "") {
      //     current.ErrorMessage = "N/A"
      //   } else {
      //     validationForUpload.push("Not Valid")
      //     productvalidation.push(current)
      //   }

      //   return acc
      // }, [])
      setvalidationError({
        ...validationError,
        product: productvalidation,
        size: sizevalidation,
        color: colorvalidation,
        category: categoryvalidation,
      })
      setcategory(categories)
      setcolorArr(color)
      setproductArr(productError)
      setsizeArr(size)
      setLoading(false)
      document.getElementById("inputGroupFile").value = ""
    }, 2000)
    setflagUpload(true)
  }
  const t_col1 = () => {
    setcol1(!col1)
  }

  const t_col2 = () => {
    setcol2(!col2)
  }

  const t_col3 = () => {
    setcol3(!col3)
  }
  const t_col4 = () => {
    setcol4(!col4)
  }

  const handleSubmit = () => {
    setLoading(true)
    postApi("Product/import-products", {
      createCategory: categories,
      createColor: color,
      createSize: size,
      createProducts: products,
    }).then(({ data, status }) => {
      if (status == 200) {
        if (data) {
          toast.success("Successfully Created", {
            style: {
              fontSize: "15px",
            },
          })
          setLoading(false)
          setproductArr(data?.ProductsError)

          if (data.ProductsError.length > 1) {
            productvalidation.push({ yes: "true" })
            setvalidation(validation.push({ yes: "true" }))
          }
        }
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
        setLoading(false)
      }
    })
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Card>
          {loading && (
            <div className="loading-overlay is-active">
              <span className="fas fa-spinner fa-3x fa-spin"></span>
            </div>
          )}
          <CardBody>
            <div className="d-flex justify-content-end align-items-center gap-2">
              <input
                type="file"
                name="file"
                ref={inputRef}
                className="custom-file-input"
                style={{ display: "none" }}
                id="inputGroupFile"
                required
                onChange={handleImport}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <a
                href={chargeSample}
                className="btn bg-info text-light mb-2"
                download="your file name"
                ref={exelFile}
                style={{ display: "none" }}
              >
                Download Template
              </a>
              <Row>
                <Col md={4} className="mb-3">
                  <button
                    type="button"
                    className="btn btn-primary  btn-label"
                    onClick={handleDownloadExel}
                  >
                    <i className="mdi mdi-download  label-icon"></i> Download
                  </button>
                </Col>
                <Col md={4} className="mb-3">
                  <button
                    type="button"
                    className="btn btn-light  btn-label"
                    onClick={handleClick}
                  >
                    <i className="mdi mdi-upload  label-icon "></i> Upload
                  </button>
                </Col>
                <Col md={4} className="mb-3">
                  {!validationForUpload.length &&
                    (category.length ||
                      colorArr.length ||
                      sizeArr.length ||
                      productArr.length) &&
                    !productArr.length ? (
                    <button
                      type="button"
                      className="btn btn-success  btn-label"
                      onClick={() => {
                        handleSubmit()
                      }}
                    >
                      <i className="bx bx-check-double label-icon"></i> Submit
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-success  btn-label"
                      style={{ cursor: "not-allowed" }}
                    >
                      <i className="bx bx-check-double label-icon"></i> Submit
                    </button>
                  )}
                </Col>
              </Row>
            </div>
            <div className="accordion mt-4" id="accordion">
              {flagUpload ? (
                <>
                  {categoryvalidation.length ? (
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            {
                              collapsed: !col1,
                            }
                          )}
                          type="button"
                          onClick={() => {
                            t_col1()
                          }}
                          style={{
                            cursor: "pointer",
                            fontSize: "16px",
                          }}
                        >
                          Category
                        </button>
                      </h2>

                      <Collapse isOpen={col1} className="accordion-collapse">
                        <div className="accordion-body">
                          {loading && (
                            <div className="loading-overlay is-active">
                              <span className="fas fa-spinner fa-3x fa-spin"></span>
                            </div>
                          )}
                          <CommonValidationTable category={category} />
                        </div>
                      </Collapse>
                    </div>
                  ) : (
                    ""
                  )}

                  {sizevalidation.length ? (
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            {
                              collapsed: !col2,
                            }
                          )}
                          type="button"
                          onClick={t_col2}
                          style={{
                            cursor: "pointer",
                            fontSize: "16px",
                          }}
                        >
                          Size
                        </button>
                      </h2>

                      <Collapse isOpen={col2} className="accordion-collapse">
                        <div className="accordion-body">
                          {loading && (
                            <div className="loading-overlay is-active">
                              <span className="fas fa-spinner fa-3x fa-spin"></span>
                            </div>
                          )}
                          <>
                            <CommonValidationTable category={sizeArr} />
                          </>
                        </div>
                      </Collapse>
                    </div>
                  ) : (
                    ""
                  )}

                  {colorvalidation.length ? (
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            {
                              collapsed: !col3,
                            }
                          )}
                          type="button"
                          onClick={t_col3}
                          style={{
                            cursor: "pointer",
                            fontSize: "16px",
                          }}
                        >
                          Color
                        </button>
                      </h2>
                      <Collapse isOpen={col3} className="accordion-collapse">
                        <div className="accordion-body">
                          {loading}
                          <>
                            <CommonValidationTable category={colorArr} />
                          </>
                        </div>
                      </Collapse>
                    </div>
                  ) : (
                    ""
                  )}
                  {productArr.length ? (
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            {
                              collapsed: !col4,
                            }
                          )}
                          type="button"
                          onClick={t_col4}
                          style={{
                            cursor: "pointer",
                            fontSize: "16px",
                          }}
                        >
                          Product
                        </button>
                      </h2>
                      <Collapse isOpen={col4} className="accordion-collapse">
                        <div className="accordion-body">
                          {loading && (
                            <div className="loading-overlay is-active">
                              <span className="fas fa-spinner fa-3x fa-spin"></span>
                            </div>
                          )}
                          <>
                            <CommonValidationTable category={productArr} />

                          </>
                        </div>
                      </Collapse>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : null}
            </div>

            {!validationError.category.length &&
              !validationError.color.length &&
              !validationError.size.length &&
              !productArr.length &&
              flagUpload ? (
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Tbody>
                      <Tr>
                        <Th
                          className="text-center"
                          style={{
                            color: "#28A745",
                            fontSize: "20px",
                          }}
                        >
                          Your details have been thoroughly verified and are
                          ready for submission. Please click on Submit.
                        </Th>
                      </Tr>
                    </Tbody>
                  </Table>
                </div>
              </div>
            ) : null}
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default ImportProduct
