import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Card, CardBody } from "reactstrap"
import { dateFormat, dateTimeFormat, getCurrentFinancialYear } from "pages/Common"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import BookedOrdersTable from "./BookedOrdersTable"
import { useSelector } from "react-redux"
import InvoiceDetalis from "pages/Invoices/InvoiceDetalis"
import TransactionModel from "pages/Transiction/Booking/TransactionModel"
import { exportDataExcel } from "pages/ExportData"

function BookedOrders() {
  const { postApi } = useApi()
  let { pathname } = useLocation()
  // useStates
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [searchText, setSearchText] = useState("")
  const [TotalBillAmount, setTotalAmount] = useState(0)
  const [TotalRecords, setTotalRecords] = useState(0)
  const [TotalAdvance, setTotalAdvance] = useState(0)
  const [TotalGSTAmt, setTotalGSTAmt] = useState(0)
  const [TotalCGSTAmt, setTotalCGSTAmt] = useState(0)
  const [TotalSGSTAmt, setTotalSGSTAmt] = useState(0)
  const [displayStart, setDisplayStart] = useState(1)
  const [screeLoad, setscreeLoad] = useState(false)
  const [transactionModelFlag, setTransactionModelFlag] = useState(false)
  const [BookingIdandStatusId, setBookingIdandStatusId] = useState({ bookingId: "", statusId: "", productId: "", returned: false })
  const [billNo, setBillNo] = useState()
  const [modal, setModal] = useState(false)
  const [date, setdate] = useState([new Date(), new Date()])
  const financeAmountShowHide = useSelector(state => state.ReduxData.finaceAmountShow);
  const CGSTPer = parseFloat(localStorage.getItem("GST").split("|")[0] || 0)
  const SGSTPer = parseFloat(localStorage.getItem("GST").split("|")[1] || 0)

  useEffect(() => {
    const startYear = localStorage.getItem("FinancialYearFrom")
    const endYear = localStorage.getItem("FinancialYearTo")
    const SelectedFinancialYear = startYear?.split("-")[0] + "-" + (endYear?.split("-")[0])
    if (SelectedFinancialYear == getCurrentFinancialYear()) {
      setdate([new Date(), new Date()])
    } else {
      let Date1 = [`01-04-${SelectedFinancialYear?.split('-')[0]}`, `31-03-${SelectedFinancialYear?.split('-')[1]}`]
      const convertedDates = Date1.map(dateStr => {
        const [day, month, year] = dateStr.split('-');
        const dateObj = new Date(year, month - 1, day);
        return dateObj
      });
      setdate(convertedDates)
    }
  }, [localStorage.getItem("FinancialYearDate")])

  // BookedOrders list api useEffect
  useEffect(() => {
    if (date[1] != undefined) {
      BookedOrdersReportApi()
    }
  }, [date, rowsPerPage, searchText, displayStart])

  // BookedOrders list api
  const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
    setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
    setSearchText(serchValue == undefined ? "" : serchValue)
    setRowsPerPage(pageSize)
  }

  const BookedOrdersReportApi = () => {
    setscreeLoad(true)
    postApi("Report/booked-report", {
      fromDate: dateFormat(date[0], "yyyy-MM-dd"),
      toDate: dateFormat(date[1], "yyyy-MM-dd"),
      displayStart: displayStart,
      pageSize: rowsPerPage,
      searchText: searchText.trim(),
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setTableData(data?.ListBookedReport)
        setTotalAmount(data?.TotalBillAmount)
        setTotalRecords(data?.TotalRecord)
        setTotalAdvance(data?.TotalAdvance)
        setTotalGSTAmt(data?.TotalGST)
        setTotalCGSTAmt(data?.TotalCGST)
        setTotalSGSTAmt(data?.TotalSGST)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
      setscreeLoad(false)
    })
  }

  const toggle = () => {
    setModal(!modal)
  }

  const handleViewBooking = bookingId => {
    setBookingIdandStatusId({ ...BookingIdandStatusId, bookingId: bookingId })
    toggle()
  }

  const toggleTransaction = () => {
    setTransactionModelFlag(!transactionModelFlag)
  }

  // view all transaction handler
  const handleTransaction = (bookingId, billNo) => {
    if (bookingId) {
      setBillNo(billNo)
      setBookingIdandStatusId({ ...BookingIdandStatusId, bookingId: bookingId })
      toggleTransaction()
    }
  }

  // header & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Bill No.",
        accessor: "BillNo",
        width: "4%",
        Cell: ({ row }) => {

          return <>
            {row.original.TotalProduct == 0 ? (
              <div style={{ color: "#E94560" }} >{row.original.BillNo}</div>
            ) : (
              <div
                style={{ cursor: "pointer", color: "#556ee6" }}
                onClick={() => {
                  handleViewBooking(row.original.Id)
                }}
              >
                {row.original.BillNo}
              </div>
            )}
          </>
        },
      },
      {
        Header: "Customer Name",
        accessor: "CustomerName",
      },
      {
        Header: "Customer No.",
        accessor: "ContactNo1",
        width: "9%",
      },
      {
        Header: "No. of Product ",
        accessor: "NumberOfProduct",
        Cell: ({ row }) => (
          <p className=" mb-0" style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center"
          }}
          >
            {row.original.NumberOfProduct}
          </p>
        ),
        width: "5%",
      },
      {
        Header: "Bill Date",
        accessor: "BillDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.BillDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateTimeFormat(
                row.original.BillDate,
                "dd/MM/yyyy HH:mm tt"
              )}
          </p>
        ),
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Discount
          </div>
        ),
        accessor: "Discount",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.Discount || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Advance
          </div>
        ),
        accessor: "Advance",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.Advance || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Deposit
          </div>
        ),
        accessor: "Deposit",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.Deposit || 0)}

            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            CGST Amt. ({CGSTPer || 0}%)
          </div>
        ),
        accessor: "CGSTAmount",
        width: "8%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(Math.round(row?.original?.CGSTAmount) || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            SGST Amt. ({SGSTPer || 0}%)
          </div>
        ),
        accessor: "SGSTAmount",
        width: "8%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(Math.round(row?.original?.SGSTAmount) || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Bill Amt.
          </div>
        ),
        accessor: "Amount",
        width: "8%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.Amount || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "6%",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              <li className="mytooltip">
                <i
                  className="fas fa-handshake"
                  style={{ color: "#556ee6", cursor: "pointer" }}
                  onClick={() => handleTransaction(row.original.Id, row.original.BillNo)}
                ></i>
                <span className="mytext">Transaction</span>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData]
  )

  function writeDataToExcel() {
    setIsLoading(true)
    let header = ["Bill No.", "Name", "Customer No.", "Bill Date", "Discount", "Advance", "Deposit", "CGST Amt.", "SGST Amt.", "Amount"];
    const mainTitle = "Booked Oreders";
    const widthObj = [{
      Key: "A",
      Value: 10,
    },
    {
      Key: "B",
      Value: 25,
    },
    {
      Key: "C",
      Value: 15,
    },
    {
      Key: "D",
      Value: 13,
    },
    {
      Key: "E",
      Value: 10,
    },
    {
      Key: "F",
      Value: 10,
    },
    {
      Key: "G",
      Value: 12,
    },
    {
      Key: "H",
      Value: 13,
    },
    {
      Key: "I",
      Value: 12,
    },
    {
      Key: "H",
      Value: 12,
    },
    ]
    const extention = "Booked_Orders.xlsx"
    let excelSheetData = []
    postApi("Report/booked-report", {
      fromDate: dateFormat(date[0], "yyyy-MM-dd"),
      toDate: dateFormat(date[1], "yyyy-MM-dd"),
      displayStart: displayStart,
      pageSize: 100000000,
      searchText: searchText.trim(),
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        for (let index = 0; index < data?.ListBookedReport?.length; index++) {
          let dataObj = {
            BillNo: data?.ListBookedReport[index].BillNo,
            Name: data?.ListBookedReport[index].CustomerName,
            ContactNo: data?.ListBookedReport[index].ContactNo1,
            BillDate: dateFormat(data?.ListBookedReport[index].BillDate.split("T")[0],
              "dd/MM/yyyy"),
            Discount: Math.round(data?.ListBookedReport[index].Discount).toFixed(2),
            Advance: Math.round(data?.ListBookedReport[index]?.Advance).toFixed(2),
            Deposit: Math.round(data?.ListBookedReport[index]?.Deposit).toFixed(2),
            CGSTAmount: Math.round(data?.ListBookedReport[index]?.CGSTAmount).toFixed(2),
            SGSTAmount: Math.round(data?.ListBookedReport[index]?.SGSTAmount).toFixed(2),
            BillAmount: Math.round(data?.ListBookedReport[index]?.Amount).toFixed(2),
          }
          excelSheetData.push(dataObj)
        }

      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
      setscreeLoad(false)

      var fields = Object.keys(excelSheetData[0]);
      var sheetData = excelSheetData.map(function (row) {
        return fields.map(function (fieldName) {
          return row[fieldName];
        });
      });
      sheetData.unshift(header);
      exportDataExcel(sheetData, mainTitle, widthObj, extention, data?.TotalBillAmount, data?.TotalDiscount, data?.TotalAdvance, data?.TotalDeposit, data?.TotalCGST, data?.TotalSGST)
    })
  }

  document.title = `Booked Ordes | ${localStorage.getItem("ShopName")}`

  return (
    <div className="page-content">
      <div className="container-fluid">
        {screeLoad && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <BookedOrdersTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              customPageSize={10}
              isLoading={isLoading}
              className="custom-header-css"
              pagePerSize={pagePerSize}
              TotalBillAmount={TotalBillAmount}
              TotalRecords={TotalRecords}
              TotalGSTAmt={TotalGSTAmt}
              financeAmountShowHide={financeAmountShowHide}
              TotalAdvance={TotalAdvance}
              setdate={setdate}
              date={date}
              writeDataToExcel={writeDataToExcel}
              TotalCGSTAmt={TotalCGSTAmt}
              TotalSGSTAmt={TotalSGSTAmt}
            />

            <InvoiceDetalis
              toggle={toggle}
              modal={modal}
              bookingId={BookingIdandStatusId.bookingId}
            />

            <TransactionModel
              modal={transactionModelFlag}
              toggle={toggleTransaction}
              BookingandStatus={BookingIdandStatusId}
              billNo={billNo}
            />

          </CardBody>
        </Card>
      </div>
    </div>
  )
}
BookedOrders.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default BookedOrders
