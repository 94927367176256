// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Card, CardBody, Input, FormGroup } from "reactstrap"

import IncomeExpenseReportTable from "./IncomeExpenseReportTable"
import useApi from "common/ApiMiddlewere"
import { dateFormat } from "pages/Common"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { toast } from "react-hot-toast"

function IncomeExpenseReport() {
  const { activeInavtiveApi, postApi } = useApi()
  document.title = `IncomeExpense | ${localStorage.getItem("ShopName")}`

  // useStates
  let { pathname } = useLocation()
  const [tableData, setTableData] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [searchText, setSearchText] = useState("")
  const [displayStart, setDisplayStart] = useState(1)
  const [Loding, setLoding] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dateDisble, setdateDisble] = useState(true)
  const [date, setdate] = useState([new Date(), new Date()])
  const [TotalRecords, setTotalRecords] = useState(0)


  const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
    setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
    setSearchText(serchValue == undefined ? "" : serchValue)
    setRowsPerPage(pageSize)
  }


  const incomeExpenseApi = () => {
    setIsLoading(true)
    setLoding(true)
    postApi("Report/finance-report", {
      fromDate: dateDisble ? dateFormat(date[0], "yyyy-MM-dd") : null,
      toDate: dateDisble ? dateFormat(date[1], "yyyy-MM-dd") : null,
      displayStart: displayStart,
      pageSize: rowsPerPage,
      searchText: searchText.trim(),
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setTableData(data?.FinanceList)
        setTotalRecords(data?.TotalRecord)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
      setIsLoading(false)
      setLoding(false)
    })
  }

  useEffect(() => {
    if (date[1] != undefined) {
      incomeExpenseApi()
    }
  }, [date, rowsPerPage, searchText, displayStart, dateDisble])

  const handleSwitch = id => {
    activeInavtiveApi(`Feedback/Reviewe?id=${id}`)
  }

  // table headers & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Invoice Number",
        accessor: "InvoiceNumber",
        width: "6%",
      },
      {
        Header: "Name",
        accessor: "FullName",
      },
      {
        Header: "Plan Name",
        accessor: "PlanName",
        width: "10%",
      },
      {
        Header: "Shop Name",
        accessor: "ShopName",
        width: "8%",
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Amount
          </div>
        ),
        accessor: "Amount",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.Amount || 0)}
            </div>
          )
        },
      },
      {
        Header: "Remarks",
        accessor: "Remarks",
        Cell: ({ row }) => (
          <p
            className=" mb-0"
            // style={{width:"120px"}}
            data-toggle="tooltip"
            data-placement="bottom"
            title={`${row.original.Remarks}`}
          >
            {row.original.Remarks?.length > 30
              ? `${row.original.Remarks.substr(0, 30)}...`
              : row.original.Remarks == ""
                ? "N/A"
                : row.original.Remarks == null
                  ? "N/A"
                  : row.original.Remarks}
          </p>
        ),
      },
      {
        Header: "Date",
        accessor: "Date",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.Date == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row.original.Date.split("T")[0],
                "dd/MM/yyyy"
              )}
          </p>
        ),
      },

    ],
    [tableData]
  )

  return (
    <div className="page-content">
      <div className="container-fluid">
        {isLoading && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <IncomeExpenseReportTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              isColorList={true}
              isLoading={Loding}
              customPageSize={10}
              className="custom-header-css"
              pagePerSize={pagePerSize}
              TotalRecords={TotalRecords}
              dateDisble={dateDisble}
              setdateDisble={setdateDisble}
              date={date}
              setdate={setdate}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

IncomeExpenseReport.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default IncomeExpenseReport
