import React, { useEffect, useState } from 'react'
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import useApi from 'common/ApiMiddlewere';

function CreateAccountCommonModel({ modal,
    toggle, Account, accountGroupApi }) {
    const { postApi, getApi } = useApi()
    const [accountGroup, setAccountsGroup] = useState([])
    const [formSumitLoading, setformSumitLoading] = useState(false)

    useEffect(() => {
        getApi("Accounts/account-group-lookup").then(({ data, status }) => {
            if (status === 200) {
                const AccountsIds = Account == 'Income' ? [6] : [7]    // Display Accounts Ids:  Vendors = 4,Income = 6, Expenses = 7 
                let AccountGroup = data?.filter(item => AccountsIds?.includes(item?.Key));
                setAccountsGroup(AccountGroup)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }, [])

    // Accounts Validation
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Name: "",
            ContactNo: "",
            Email: "",
            ShopName: "",
            OpeningBalance: "",
            Address: "",
            Remark: "",
            AccountGroup: 0,
        },
        validationSchema: Yup.object({
            Name: Yup.string().required("The name is required"),
            ContactNo: Yup.string().required("The contact no. is required"),
            AccountGroup: Yup.number().min(1, "The account group is required"),
        }),
        onSubmit: values => {
            setformSumitLoading(true)
            if (values) {
                postApi("Accounts/create", {
                    name: values.Name || '',
                    accountGroup: parseInt(values.AccountGroup),
                    email: values.Email || '',
                    contactNo: values.ContactNo || '',
                    shopName: values.ShopName || '',
                    address: values.Address || '',
                    openingBalance: parseFloat(values.OpeningBalance) || 0,
                    remarks: values.Remark || '',
                }).then(({ data, status }) => {
                    setformSumitLoading(false)
                    if (status == 200) {
                        toast.success("successfully created", {
                            style: {
                                fontSize: "15px",
                            },
                        })
                        accountGroupApi()
                        toggle()
                        validation.handleReset()
                    } else {
                        toast.error(data, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                    }
                })
            }
        },
    })

    const handleKeyPress = event => {
        const charCode = event.which || event.keyCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    return (
        <div>
            <Modal
                isOpen={modal}
                toggle={toggle}
                centered={true}
                size="small"
                style={{ width: "500px" }}
            >
                <div className="modal-content">
                    <ModalHeader toggle={toggle} tag="h5">
                        {"Create Account"}
                    </ModalHeader>
                    <ModalBody>
                        <Form
                            onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                            }}
                            onReset={e => {
                                e.preventDefault
                                validation.handleReset()
                                return false
                            }}
                        >
                            <Row>
                                <Col md={12}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03" className="required">
                                            Name
                                        </Label>
                                        <Input
                                            name="Name"
                                            placeholder="Name"
                                            type="text"
                                            className="form-control-sm"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.Name || ""}
                                            invalid={
                                                validation.touched.Name && validation.errors.Name
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.Name && validation.errors.Name ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.Name}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <label
                                        htmlFor="name"
                                        className="required form-label"
                                    >
                                        Account Group
                                    </label>
                                    <Input
                                        type="select"
                                        id="validationCustom02"
                                        // disabled={isEdit}
                                        bsSize="sm"
                                        className="form-control-sm"
                                        name="AccountGroup"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.AccountGroup}
                                        invalid={
                                            validation.touched.AccountGroup &&
                                                validation.errors.AccountGroup
                                                ? true
                                                : false
                                        }
                                    >
                                        <option value={0}>Select...</option>
                                        {accountGroup.map((item, index) => {
                                            return (
                                                <option key={index} value={item.Key}>
                                                    {item.Value}
                                                </option>
                                            )
                                        })}
                                    </Input>
                                    {validation.touched.AccountGroup &&
                                        validation.errors.AccountGroup ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.AccountGroup}
                                        </FormFeedback>
                                    ) : null}
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label
                                            htmlFor="validationCustom03"
                                            className="required"
                                        >
                                            Contact No
                                        </Label>
                                        <Input
                                            name="ContactNo"
                                            placeholder="Contact No."
                                            type="text"
                                            maxLength="10"
                                            className="form-control-sm"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.ContactNo || ""}
                                            onKeyPress={handleKeyPress}
                                            invalid={
                                                validation.touched.ContactNo &&
                                                    validation.errors.ContactNo
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.ContactNo &&
                                            validation.errors.ContactNo ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.ContactNo}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label
                                            htmlFor="validationCustom01"
                                            className="form-label"
                                        >
                                            Opening Balance
                                        </Label>
                                        <Input
                                            name="OpeningBalance"
                                            // disabled={isEdit}
                                            className="form-control-sm"
                                            placeholder="0.00"
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            onKeyPress={handleKeyPress}
                                            onFocus={(e) => { e.target.select() }}
                                            value={validation.values.OpeningBalance}
                                            invalid={
                                                validation.touched.OpeningBalance &&
                                                    validation.errors.OpeningBalance
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.OpeningBalance &&
                                            validation.errors.OpeningBalance ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.OpeningBalance}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03">
                                            Email
                                        </Label>
                                        <Input
                                            name="Email"
                                            placeholder="Email"
                                            type="text"
                                            className="form-control-sm"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.Email || ""}
                                            invalid={
                                                validation.touched.Email && validation.errors.Email
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.Email && validation.errors.Email ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.Email}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03">
                                            Shop Name
                                        </Label>
                                        <Input
                                            name="ShopName"
                                            placeholder="ShopName"
                                            type="text"
                                            className="form-control-sm"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.ShopName || ""}
                                            invalid={
                                                validation.touched.ShopName && validation.errors.ShopName
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.ShopName && validation.errors.ShopName ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.ShopName}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup className="mb-2">
                                        <Label
                                            htmlFor="validationCustom03"
                                        >
                                            Address
                                        </Label>
                                        <Input
                                            name="Address"
                                            placeholder="Address"
                                            type="textarea"
                                            className="form-control-sm"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.Address || ""}
                                            invalid={
                                                validation.touched.Address &&
                                                    validation.errors.Address
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.Address &&
                                            validation.errors.Address ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.Address}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup  >
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom05">Remark</Label>
                                        <Input
                                            name="Remark"
                                            rows="2"
                                            placeholder="Type your Remark here"
                                            type="textarea"
                                            className="form-control-sm"
                                            value={validation.values.Remark || ""}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className="text-end">
                                <Button
                                    color="secondary"
                                    className="btn btn-primary me-2 btn-sm "
                                    type="reset"
                                >
                                    Reset
                                </Button>
                                <>
                                    {formSumitLoading ? (
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                        >
                                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                            Loading
                                        </button>
                                    ) : (
                                        <Button
                                            color="primary"
                                            className="btn btn-primary btn-sm "
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    )}
                                </>
                            </div>
                        </Form>
                    </ModalBody>
                </div>
            </Modal>
        </div>
    )
}

export default CreateAccountCommonModel