// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import {
    Card,
    CardBody,
} from "reactstrap"
import DeleteModel from "common/deleteModel/deleteModel"
import { dateFormat, dateFormat_dr, getCurrentFinancialYear } from "pages/Common"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { routes } from "routes/path"
import PurchaseTable from "./PurchaseTable"

function PurchaseList
    () {
    let { pathname } = useLocation()
    const { deleteApi, postApi, putApi, getApi } = useApi()
    document.title = `Purchase | ${localStorage.getItem("ShopName")}`
    const navigate = useNavigate()
    const startDate = localStorage.getItem("FinancialYearFrom")
    const endDate = localStorage.getItem("FinancialYearTo")
    // useStates
    const [tableData, setTableData] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [isLoading, setIsLoading] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [displayStart, setDisplayStart] = useState(1)
    const [TotalRecords, setTotalRecords] = useState(0)
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)
    const [dateDisble, setdateDisble] = useState(true)
    const [date, setdate] = useState([new Date(), new Date()])

    useEffect(() => {
        const SelectedFinancialYear = startDate?.split("-")[0] + "-" + (endDate?.split("-")[0])
        if (SelectedFinancialYear == getCurrentFinancialYear()) {
            setdate([new Date(), new Date()])
        } else {
            let Date1 = [`01-04-${SelectedFinancialYear?.split('-')[0]}`, `31-03-${SelectedFinancialYear?.split('-')[1]}`]
            const convertedDates = Date1.map(dateStr => {
                const [day, month, year] = dateStr?.split('-');
                const dateObj = new Date(year, month - 1, day);
                return dateObj
            });
            setdate(convertedDates)
        }
    }, [localStorage.getItem("FinancialYearDate")])


    // pagination
    const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
        setRowsPerPage(pageSize)
        setSearchText(serchValue == undefined ? "" : serchValue)
        setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
    }

    const purchaseList = () => {
        setIsLoading(true)
        postApi("Purchases/list", {
            fromDate: dateDisble ? localStorage.getItem("FinancialYearFrom") : dateFormat(date[0], "yyyy-MM-dd"),
            toDate: dateDisble ? localStorage.getItem("FinancialYearTo") : dateFormat(date[1], "yyyy-MM-dd"),
            displayStart: displayStart,
            pageSize: rowsPerPage,
            searchText: searchText.trim(),
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setTableData(data.PurchasesList
                )
                setTotalRecords(data.TotalRecords)
                setIsLoading(false)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
                setIsLoading(false)
            }
        })
    }

    useEffect(() => {
        if (date[1] != undefined) {
            purchaseList()
        }
    }, [dateDisble, date, rowsPerPage, searchText, displayStart])

    // product delete handler
    const handleDelete = purchaseItemId => {
        setIsLoading(true)
        if (purchaseItemId) {
            DeleteModel.confirmDeleteInquiry({
                title: "Are you sure? ",
                text: "You want to delete this purchase item permanently? ",
                isMobile,
            }).then(response => {
                if (response == true) {
                    deleteApi(`Purchases/delete?id=${parseInt(purchaseItemId)}`).then(
                        ({ data, status }) => {
                            if (status === 200) {
                                const updatedArray = tableData
                                    .map(obj => (obj.Id !== purchaseItemId ? obj : null))
                                    .filter(Boolean)
                                setTableData(updatedArray)
                                toast.success("Purchase item sucessfully deleted", {
                                    style: {
                                        fontSize: "15px",
                                    },
                                })
                            } else {
                                ErrorModel.errormodel({
                                    title: "Verify item",
                                    text: data,
                                })
                            }
                            setIsLoading(false)
                        }
                    )
                } else {
                    setIsLoading(false)
                }
            })
        }
    }

    const handleEdit = purchaseId => {
        if (purchaseId) {
            navigate(routes.UpdatePurchase, {
                state: {
                    purchaseId: purchaseId,
                },
            })
        }
    }

    const handlePrint = async (id) => {
        setIsLoading(true)
        getApi(`Report/sale-invoice?saleId=${id}`).then(({ data, status }) => {
            const byteCharacters = atob(data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            const url = URL.createObjectURL(blob);
            const newWindow = window.open(url);

            setIsLoading(false)
            newWindow.addEventListener('load', () => {
                newWindow.print();
            });
        })
    };

    // table headers & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Bill No",
                accessor: "BillNo",
                width: "7%",
            },
            {
                Header: "Date",
                accessor: "Date",
                width: "8%",
                Cell: ({ row }) => (
                    <span>
                        {row.original?.Date.split("T")[0] == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(row.original?.Date, "dd/MM/yyyy")}
                    </span>
                ),
            },
            {
                Header: "Vendor",
                accessor: "VendorName",
                Cell: ({ row }) => (
                    <span>
                        {row.original.VendorName ? row.original.VendorName : "N/A"}
                    </span>
                ),
            },
            {
                Header: "Contact No",
                accessor: "VendorContactNo",
                width: "8%",
                Cell: ({ row }) => (
                    <span>
                        {row.original.VendorContactNo ? row.original.VendorContactNo : "N/A"}
                    </span>
                ),
            },
            {
                Header: "Total Qty",
                accessor: "TotalQty",
                width: "6%",
                Cell: ({ row }) => (
                    <div
                        className="text-center"
                        style={{ cursor: "pointer" }}
                    // onClick={() => {
                    //     handleProductView(row.original.Id)
                    // }}
                    >
                        {row.original?.TotalQty}
                    </div>
                ),
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Bill Amt.
                    </div>
                ),
                accessor: "NetAmount",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.NetAmount || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Discount
                    </div>
                ),
                accessor: "Discount",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.Discount || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        GST Amt.
                    </div>
                ),
                accessor: "GSTAmount",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.GSTAmount || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Action
                    </div>
                ),
                width: "9%",
                accessor: "action",
                disableFilters: true,
                Cell: ({ row }) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
                            <li className="mytooltip">
                                <i
                                    className=" fs-6 fas fa-edit btn-edit "
                                    id={`edittooltip${row.original.Id}`}
                                    onClick={() => handleEdit(row.original.Id)}
                                ></i>
                                <span className="mytext">Edit</span>
                            </li>
                            <li className="mytooltip">
                                <i
                                    className="fs-6 fas fa-trash-alt btn-delete"
                                    id={`deletetooltip${row.original.Id}`}
                                    onClick={() => handleDelete(row.original.Id)}
                                ></i>
                                <span className="mytext">Delete</span>
                            </li>
                        </ul>
                    )
                },
            },
        ],
        [tableData, TotalRecords]
    )
    // redirect create product handler
    const handleUserClicks = () => {
        navigate(routes.createPurchase)
    }

    return (
        <div className="page-content">
            <div className="container-fluid">
                {isLoading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <PurchaseTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            isCreateProduct={true}
                            handleUserClick={handleUserClicks}
                            customPageSize={10}
                            className="custom-header-css"
                            pagePerSize={pagePerSize}
                            isLoading={isLoading}
                            TotalRecords={TotalRecords}
                            dateDisble={dateDisble}
                            setdateDisble={setdateDisble}
                            date={date}
                            setdate={setdate}
                        />

                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
PurchaseList
    .propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default PurchaseList

