import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import { Card, CardBody } from "reactstrap"
import { dateFormat } from "pages/Common"
import CartTable from "./CartTable"
import { toast } from "react-hot-toast"
import breadcrumbs from "../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { routes } from "routes/path"
import useApi from "common/ApiMiddlewere"
import { useDispatch, useSelector } from "react-redux"
import { addToCartRequest } from "store/addtocart/action"

function CartListTable() {
  const navigate = useNavigate()
  let { pathname } = useLocation()
  const { deleteApi, getApi } = useApi()
  const dispatch = useDispatch();
  const addToCartStatus = useSelector(state => state.ReduxData.addToCartStatus == -1 ? 0 : state.ReduxData.addToCartStatus);

  // useStates
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const cartListApi = () => {
    setIsLoading(true)
    getApi(`Cart/cart-list`, "").then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  // cart list API useEffect
  useEffect(() => {
    cartListApi()
  }, [])

  const handleDelete = id => {
    setIsLoading(true)
    deleteApi(`Cart/delete-cart?id=${id}`).then(({ data, status }) => {
      if (status === 200) {
        toast.success("Successfully deleted", {
          style: {
            fontSize: "15px",
          },
        })
        dispatch(addToCartRequest(addToCartStatus - 1));
        const updatedArray = tableData
          .map(obj => (obj.Id !== id ? obj : null))
          .filter(Boolean)
        setTableData(updatedArray)
        setIsLoading(false)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  // Redirect to create booking
  const handleCreateBooking = customerName => {
    if (customerName) {
      navigate(routes.createbooking, {
        state: {
          cartName: customerName,
        },
      })
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "Name",
      },
      {
        Header: "Salesman Name",
        accessor: "SalesmanUsername",
      },
      {
        Header: "Product Code",
        accessor: "ProductCode",
        width: "6%",
      },
      {
        Header: "Qty",
        accessor: "Qty",
        width: "4%",
      },
      {
        Header: "Delivery Date",
        accessor: "DeliveryDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row.original.DeliveryDate.split("T")[0],
                "dd/MM/yyyy"
              )}
            {row.original.DeliveryTimeSlot ? (
              <span> {` (${row.original.DeliveryTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: "Return Date",
        accessor: "ReturnDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ReturnDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
            {row.original.ReturnTimeSlot ? (
              <span>
                <span> {` (${row.original.ReturnTimeSlot})`}</span>
              </span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: "Create Date",
        accessor: "CreatedOn",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.CreatedOn == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.CreatedOn.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "6%",
        accessor: "Action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              <li className="mytooltip">
                <i
                  className=" bx bx-task"
                  style={{
                    color: "#556ee6",
                    cursor: "pointer",
                    fontSize: "15px",
                    marginTop: "3px",
                  }}
                  id={`viewtooltip${row.original.Id}`}
                  onClick={() => handleCreateBooking(row.original.Name)}
                ></i>
                <span className="mytext">Convert to Bill</span>
              </li>
              <li className="mytooltip">
                <i
                  className="fs-6 fas fa-trash-alt btn-delete"
                  id={`deletetooltip${row.original.Id}`}
                  onClick={() => handleDelete(row.original.Id)}
                ></i>
                <span className="mytext">Delete</span>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData]
  )
  document.title = `Cart | ${localStorage.getItem("ShopName")}`

  return (
    <div className="page-content">
      <div className="container-fluid">
        {isLoading && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <CartTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              customPageSize={10}
              className="custom-header-css"
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
CartListTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default CartListTable
