import React, { Fragment, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, FormGroup, Label, Accordion, AccordionItem, AccordionBody, AccordionHeader } from "reactstrap"
import { DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  setGlobalFilter,
  isJobListGlobalFilter,
  value,
  setValue,
}) {
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={12}>
        <div className="search-box w-full d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={"Search..."}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

const DeliveryTable = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  handleDownloadPDF,
  className,
  isLoading,
  pagePerSize,
  TotalRecords,
  setdateDisble,
  dateDisble,
  date, setdate,
  isSlipFormat, setIsSlipFormat,
  checkboxRef,
  checkboxInput,
  setCheckboxInput,
  categories,
  setCategoryId,
  categoryId,
  isGroupedData,
  setIsGroupedData,
}) => {
  const {
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageSize: 20,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useExpanded,
    usePagination
  )

  const checkbox = useRef();
  const [pageIndex, setpageIndex] = useState(1)
  const [value, setValue] = React.useState()
  const [prevValue, setPrevValue] = useState();

  useEffect(() => {
    pagePerSize(pageSize, pageIndex, value)
    setPrevValue(value);
  }, [pageSize, value, pageIndex])

  useEffect(() => {
    if (value !== prevValue) {
      setpageIndex(1); // Reset pageIndex to 1 when value changes
    }
  }, [value]);

  const handlesort = column => {
  }

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }


  const [isMobile, setisMobile] = useState(window.innerWidth < 768)
  const [isIpad, setIsIpad] = useState(window.innerWidth < 924)
  const [open, setOpen] = useState("")
  const [isExpanded, setIsExpanded] = useState(false);

  const margin = {
    marginTop: isMobile ? "8px" : "",
  }

  const toggleExpand = () => {
    setIsExpanded(!isExpanded); // Toggle the expanded state
  };

  const handleClick = () => {
    setdateDisble(!dateDisble);
  }
  const flatpickrRef = useRef(null);

  useEffect(() => {
    setpageIndex(1); // Reset pageIndex to 1 when value changes
  }, [pageSize]);

  const toggleAccordion = id => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  return (
    <Fragment>
      <Row>
        <div style={{ display: "flex", gap: "18px", justifyContent: "end" }}>
          <div>
            <div style={{ display: "flex", gap: "5px" }}>
              <div style={{ width: '12px', height: '12px', backgroundColor: '#b5ebb7', marginTop: "3px", border: "1px solid #8ee191" }}>   </div> <div>Delivered </div>
            </div>
          </div>
          <div>
            <div style={{ display: "flex", gap: "5px" }}>
              <div style={{ width: '12px', height: '12px', backgroundColor: '#fdefbe', marginTop: "3px", border: "1px solid #dbcb94" }}>   </div> <div>Critical </div>
            </div>
          </div>
        </div>
        <Col md={isIpad ? 12 : 8} style={{ marginTop: "10px" }}>
          <Row>
            <Col md="2" className="table-header pe-0">
              <select
                className="form-select"
                value={pageSize}
                onChange={onChangeInSelect}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </Col>
            <Col md="3" className="table-header pe-0" style={margin}>
              {isGlobalFilter && (
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  isJobListGlobalFilter={isJobListGlobalFilter}
                  value={value}
                  setValue={setValue}
                />
              )}
            </Col>
            <Col md="2"
              className={`pe-0`}
            >
              <FormGroup >
                <select
                  className="form-select"
                  value={categoryId}
                  onChange={e => setCategoryId(e.target.value)}
                >
                  {categories.map((item, index) => {
                    return (
                      <option key={index} value={item.Key}>
                        {item.Value}
                      </option>
                    )
                  })}
                </select>
              </FormGroup>
            </Col>
            <Col md={5} className="table-header pe-0" style={margin}>
              <div className=" d-flex" >
                <div className="form-control " onClick={() => handleClick()} style={{ width: "40px", borderRight: "none", backgroundColor: "#EFF2F7", display: "flex", alignItems: "center", cursor: "pointer" }}>
                  <input
                    id="checkbox"
                    onClick={() => handleClick()}
                    type="checkbox"
                    style={{ width: "15px", height: "15px", cursor: "pointer" }}
                    defaultChecked={true}
                    checked={dateDisble}
                    onChange={e => setdateDisble(e.target.checked)}
                    ref={checkbox}
                  />
                </div>
                <Flatpickr
                  ref={flatpickrRef}
                  disabled={!dateDisble}
                  className="form-control d-block"
                  placeholder="dd M,yyyy"
                  options={{
                    mode: "range",
                    dateFormat: "d-m-Y"
                  }}
                  value={date}
                  onChange={(e) => { setdate(e) }}
                />
                <i
                  className="fas fa-calendar-alt calander-icon"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    flatpickrRef.current.flatpickr.open();
                  }}
                ></i>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={2}></Col>
        <Col md={2} style={{ marginTop: "10px", textAlign: "end" }}>
          {isExpanded ?
            <button style={{ backgroundColor: "#DCDDE2 !important", color: "black" }} onClick={toggleExpand} type="button" className="btn-label btn btn-light"><i className="mdi mdi-filter-minus label-icon "></i> Filters</button>
            :
            <button style={{ backgroundColor: "#DCDDE2", color: "black" }} onClick={toggleExpand} type="button" className="btn-label btn btn-light"><i className="mdi mdi-filter-plus label-icon "></i> Filters</button>
          }
        </Col>
      </Row>
      <Row>
        <div
          style={{
            maxHeight: isExpanded ? "200px" : "0",
            overflow: "hidden",
            transition: "max-height 0.5s ease",
          }}
        >
          <Row>
            <Col md={3}>
              <FormGroup >
                <select
                  disabled={isGroupedData}
                  className="form-select"
                  value={checkboxInput}
                  onChange={e => setCheckboxInput(e.target.value)}
                >
                  <option value={null}>Prepared / Unprepared</option>
                  <option value={true}>Prepared</option>
                  <option value={false}>Unprepared</option>
                </select>
              </FormGroup>
            </Col>
            <Col md={4}>
              <div style={{ display: "flex", gap: "17px" }}>
                <FormGroup check style={{ marginTop: "8px" }}>
                  <Input
                    id="checkbox1"
                    type="checkbox"
                    defaultChecked={isGroupedData}
                    onChange={e => setIsGroupedData(e.target.checked)}
                  />
                  <Label for="checkbox1" style={{ marginTop: "1px" }} check>
                    Group by bill
                  </Label>
                </FormGroup>
                <FormGroup check style={{ marginTop: "8px" }}>
                  <Input
                    id="checkbox2"
                    type="checkbox"
                    disabled={isGroupedData}
                    defaultChecked={isSlipFormat}
                    onChange={e => setIsSlipFormat(e.target.checked)}
                  />
                  <Label for="checkbox2" style={{ marginTop: "1px" }} check>
                    Print Slip
                  </Label>
                </FormGroup>
                <div>
                  {data.length > 0 ? (<button
                    type="button"
                    className="btn btn-pdf  btn-label1"
                    onClick={handleDownloadPDF}
                  >
                    Export <i className="far fa-file-pdf label-icon1"></i>
                  </button>) :
                    (<button
                      type="button"
                      className="btn btn-pdf  btn-label1"
                      style={{ cursor: "not-allowed" }}
                    >
                      Export <i className="far fa-file-pdf label-icon1"></i>
                    </button>)
                  }
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Row>


      {/* <Row>
        <Col>
          <Accordion open={open} toggle={toggleAccordion}>
            <AccordionItem>
              <AccordionHeader targetId="1">
              </AccordionHeader>
              <AccordionBody accordionId="1">
                fgft
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </Col>
      </Row> */}

      <div className="table-responsive react-table">
        <Table bordered className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    onClick={() => {
                      handlesort(column)
                    }}
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        width: column.width,
                      },
                    })}
                  >
                    <div className={`mb-0`}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {page.length ? (
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr className="">
                      {row.cells.map(cell => {
                        const { Status, IsCritical } = cell.row.original;
                        return (
                          <td
                            key={cell.id}
                            className={`${Status == 2 ? "deliverd-status" : IsCritical ? "critical-row" : ""} text-nowrap`}
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.minWidth,
                                maxWidth: cell.maxWidth,
                                width: cell.width,
                              },
                            })}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          ) : (
            <tbody
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
              }}
            >
              <tr>
                <td colSpan={headerGroups[0].headers.length}>
                  {isLoading ? "Loading..." : "No Record Found"}
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => setpageIndex(pageIndex - 1)}
              disabled={pageIndex == 1}
            >
              {"<<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page
          <strong style={{ marginLeft: "5px" }}>
            {TotalRecords == 0 ? 1 : pageIndex} of {TotalRecords == 0 ? 0 : Math.ceil(TotalRecords / pageSize)}
          </strong> | Total <strong>{TotalRecords}</strong>
        </Col>
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => setpageIndex(pageIndex + 1)}
              disabled={TotalRecords == 0 ? true : pageIndex == Math.ceil(TotalRecords / pageSize)}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

DeliveryTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DeliveryTable
