import React, { Fragment, useRef } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button } from "reactstrap"
import { DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import { useEffect, useState } from "react"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { dateFormat } from "pages/Common"

// Define a default UI for filtering
function GlobalFilter({
  setGlobalFilter,
  isJobListGlobalFilter,
  value,
  setValue,
}) {
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={12}>
        <div className="search-box w-full d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={"Search billno..."}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}


const BookedOrdersTable = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  TotalBillAmount,
  className,
  pagePerSize,
  isLoading,
  TotalRecords,
  TotalAdvance,
  setdate, date,
  writeDataToExcel,
  TotalCGSTAmt,
  TotalSGSTAmt,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      manualPagination: true,
      initialState: {
        // pageIndex: 0,
        pageSize: 20,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useExpanded,
    usePagination
  )
  const [pageIndex, setpageIndex] = useState(1)

  const handlesort = () => { }

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const [value, setValue] = React.useState()
  const [prevValue, setPrevValue] = useState();

  useEffect(() => {
    pagePerSize(pageSize, pageIndex, value)
    setPrevValue(value)
  }, [pageSize, value, pageIndex])

  useEffect(() => {
    if (value !== prevValue) {
      setpageIndex(1);
    }
  }, [value]);

  useEffect(() => {
    setpageIndex(1);
  }, [pageSize]);

  const [isMobile, setisMobile] = useState(window.innerWidth < 924)
  const [isIpad, setIpad] = useState(window.innerWidth < 924)

  const margin = {
    marginTop: isMobile ? "5px" : "35px",
  }

  const flatpickrRef = useRef(null);

  return (
    <Fragment>
      <Row className="">
        <Col className="table-header" md={isMobile ? 12 : 3} >
          <Row>
            <Col md={isMobile ? 12 : 6} style={margin} >
              <select
                className="form-select form-control"
                value={pageSize}
                onChange={onChangeInSelect}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={isMobile ? 12 : 6} style={{ marginTop: isMobile ? "12px" : "35px" }}>
              {isGlobalFilter && (
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  isJobListGlobalFilter={isJobListGlobalFilter}
                  value={value}
                  setValue={setValue}
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col md={3} className="table-header" >
          <div className=" d-flex" style={margin}>
            <Flatpickr
              ref={flatpickrRef}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                mode: "range",
                dateFormat: "d-m-Y",
                maxDate: dateFormat(localStorage.getItem("FinancialYearTo"), "dd-MM-yyyy"),
                minDate: dateFormat(localStorage.getItem("FinancialYearFrom"), "dd-MM-yyyy"),
              }}
              value={date}
              onChange={(e) => { setdate(e) }}

            />
            <i
              className="fas fa-calendar-alt calander-icon"
              style={{ cursor: 'pointer', marginTop: '35px' }}
              onClick={() => {
                flatpickrRef.current.flatpickr.open();
              }}
            ></i>
          </div>
        </Col>
        <Col md={2} className="table-header" >
          <div style={margin}>
            {data.length > 0 ? (<button
              type="button"
              className="btn btn-excel  btn-label"
              onClick={writeDataToExcel}
            >
              Excel <i className="far fa-file-excel label-icon"></i>
            </button>) : (<button
              type="button"
              className="btn  btn-excel  btn-label"
              style={{ cursor: "not-allowed" }}
            >
              Excel <i className="far fa-file-excel label-icon"></i>
            </button>)}
          </div>
        </Col>
        <Col md={4} className="table-header mb-2">
          <Row>
            <Col md={6} style={{
              textAlign: "start"
            }}>
              <div style={{ fontSize: "12px" }}> Total Billing</div>
              <div
                style={{ fontSize: "14px", color: "green" }}>
                <>
                  <i className="mdi mdi-currency-inr"></i>
                  {new Intl.NumberFormat('en-IN', {
                    currency: 'INR',
                    minimumFractionDigits: 2,
                  }).format(TotalBillAmount || 0)}
                </>
              </div>
              <div style={{ fontSize: "12px" }}>Total Earning</div>
              <div style={{ fontSize: "14px", color: "green" }}>
                <>
                  <i className="mdi mdi-currency-inr"></i>
                  {new Intl.NumberFormat('en-IN', {
                    currency: 'INR',
                    minimumFractionDigits: 2,
                  }).format(TotalAdvance || 0)}
                </>
              </div>
            </Col>
            <Col md={6} style={{ textAlign: isMobile ? "start" : "end" }}>
              <div style={{ fontSize: "12px" }}>Total CGST</div>
              <div style={{ fontSize: "14px", color: "blue", wordSpacing: "-5px" }}>    <>
                <i className="mdi mdi-currency-inr"></i>
                {new Intl.NumberFormat('en-IN', {
                  currency: 'INR',
                  minimumFractionDigits: 2,
                }).format(Math.round(TotalCGSTAmt || 0))}
              </></div>
              <div style={{ fontSize: "12px" }}>Total SGST</div>
              <div style={{ fontSize: "14px", color: "blue", wordSpacing: "-5px" }}> <>
                <i className="mdi mdi-currency-inr"></i>
                {new Intl.NumberFormat('en-IN', {
                  currency: 'INR',
                  minimumFractionDigits: 2,
                }).format(Math.round(TotalSGSTAmt || 0))}
              </></div>
            </Col>
          </Row>
        </Col>
      </Row>


      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    onClick={() => {
                      handlesort(column)
                    }}
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        width: column.width,
                      },
                    })}
                  >
                    <div className={`mb-0`}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {page.length ? (
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map(cell => {
                        return (
                          <td
                            key={cell.id}
                            className="text-nowrap"
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.minWidth,
                                maxWidth: cell.maxWidth,
                                width: cell.width,
                              },
                            })}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          ) : (
            <tbody
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
              }}
            >
              <tr>
                <td colSpan={headerGroups[0].headers.length}>
                  {isLoading ? "Loading..." : "No Record Found"}
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => setpageIndex(pageIndex - 1)}
              disabled={pageIndex == 1}
            >
              {"<<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page
          <strong style={{ marginLeft: "5px" }}>
            {TotalRecords == 0 ? 1 : pageIndex} of {TotalRecords == 0 ? 0 : Math.ceil(TotalRecords / pageSize)}
          </strong> | Total <strong>{TotalRecords}</strong>
        </Col>
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => setpageIndex(pageIndex + 1)}
              disabled={TotalRecords == 0 ? true : pageIndex == Math.ceil(TotalRecords / pageSize)}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

BookedOrdersTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default BookedOrdersTable
