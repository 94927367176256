import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Card, CardBody } from "reactstrap"
import { dateFormat, getCurrentFinancialYear } from "pages/Common"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import SalesGSTReportTable from "./SalesGSTReportTable"
import { useSelector } from "react-redux"
import { exportDataExcelSales } from "pages/ExportData"

function SalesGstReport() {
    const { postApi } = useApi()
    let { pathname } = useLocation()
    // useStates
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [searchText, setSearchText] = useState("")
    const [TotalBillAmount, setTotalAmount] = useState(0)
    const [TotalRecords, setTotalRecords] = useState(0)
    const [TotalAdvance, setTotalAdvance] = useState(0)
    const [TotalGSTAmt, setTotalGSTAmt] = useState(0)
    const [TotalCGSTAmt, setTotalCGSTAmt] = useState(0)
    const [TotalSGSTAmt, setTotalSGSTAmt] = useState(0)
    const [TotalIGSTAmt, setTotalIGSTAmt] = useState(0)
    const [displayStart, setDisplayStart] = useState(1)
    const [screeLoad, setscreeLoad] = useState(false)
    const [date, setdate] = useState([new Date(), new Date()])
    const financeAmountShowHide = useSelector(state => state.ReduxData.finaceAmountShow);

    useEffect(() => {
        const startYear = localStorage.getItem("FinancialYearFrom")
        const endYear = localStorage.getItem("FinancialYearTo")
        const SelectedFinancialYear = startYear?.split("-")[0] + "-" + (endYear?.split("-")[0])
        if (SelectedFinancialYear == getCurrentFinancialYear()) {
            setdate([new Date(), new Date()])
        } else {
            let Date1 = [`01-04-${SelectedFinancialYear?.split('-')[0]}`, `31-03-${SelectedFinancialYear?.split('-')[1]}`]
            const convertedDates = Date1.map(dateStr => {
                const [day, month, year] = dateStr.split('-');
                const dateObj = new Date(year, month - 1, day);
                return dateObj
            });
            setdate(convertedDates)
        }
    }, [localStorage.getItem("FinancialYearDate")])

    // SalesGstReport list api useEffect
    useEffect(() => {
        if (date[1] != undefined) {
            SalesGstReportReportApi()
        }
    }, [date, rowsPerPage, searchText, displayStart])

    // SalesGstReport list api
    const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
        setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
        setSearchText(serchValue == undefined ? "" : serchValue)
        setRowsPerPage(pageSize)
    }

    const SalesGstReportReportApi = () => {
        setscreeLoad(true)
        postApi("Report/sale-report", {
            fromDate: dateFormat(date[0], "yyyy-MM-dd"),
            toDate: dateFormat(date[1], "yyyy-MM-dd"),
            displayStart: displayStart,
            pageSize: rowsPerPage,
            searchText: searchText.trim(),
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setTableData(data?.ListSaleReport)
                setTotalAmount(data?.TotalNetAmount)
                setTotalRecords(data?.TotalRecord)
                setTotalCGSTAmt(data?.TotalCGST)
                setTotalSGSTAmt(data?.TotalSGST)
                setTotalIGSTAmt(data?.TotalIGST)
                setTotalAdvance(data?.TotalAdvance)
                setTotalGSTAmt(data?.TotalGST)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setscreeLoad(false)
        })
    }

    // header & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Bill No.",
                accessor: "BillNo",
                width: "4%",
            },
            {
                Header: "Customer Name",
                accessor: "Name",
            },
            {
                Header: "Customer No.",
                accessor: "ContactNo",
                width: "9%",
            },
            {
                Header: "Total Qty",
                accessor: "TotalQty",
                width: "5%",
            },
            {
                Header: "Bill Date",
                accessor: "Date",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.BillDate == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(row.original.BillDate.split("T")[0], "dd/MM/yyyy")}
                    </p>
                ),
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Discount
                    </div>
                ),
                accessor: "Discount",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(Math.round(row?.original?.Discount) || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        CGST Amt.
                    </div>
                ),
                accessor: "CGSTAmount",
                width: "8%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(Math.round(row?.original?.CGSTAmount) || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        SGST Amt.
                    </div>
                ),
                accessor: "SGSTAmount",
                width: "8%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(Math.round(row?.original?.SGSTAmount) || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        IGST Amt.
                    </div>
                ),
                accessor: "IGSTAmount",
                width: "8%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(Math.round(row?.original?.IGSTAmount) || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Net Amt.
                    </div>
                ),
                accessor: "NetAmount",
                width: "8%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(Math.round(row?.original?.NetAmount) || 0)}
                        </div>
                    )
                },
            },
        ],
        [tableData]
    )

    function writeDataToExcel() {
        setIsLoading(true)
        let header = ["Bill No.", "Name", "Customer No.", "Total Qty", "Bill Date", "Discount", "CGST Amt.", "SGST Amt.", "IGST Amt.", "NetAmount"];
        const mainTitle = "Sales";
        const widthObj = [{
            Key: "A",
            Value: 10,
        },
        {
            Key: "B",
            Value: 40,
        },
        {
            Key: "C",
            Value: 14,
        },
        {
            Key: "D",
            Value: 10,
        },
        {
            Key: "E",
            Value: 12,
        },
        {
            Key: "F",
            Value: 10,
        },
        {
            Key: "G",
            Value: 12,
        },
        {
            Key: "H",
            Value: 13,
        },
        {
            Key: "I",
            Value: 12,
        },
        {
            Key: "J",
            Value: 15,
        },
        ]
        let excelSheetData = []
        const extention = "Sales.xlsx"
        postApi("Report/sale-report", {
            fromDate: dateFormat(date[0], "yyyy-MM-dd"),
            toDate: dateFormat(date[1], "yyyy-MM-dd"),
            displayStart: displayStart,
            pageSize: 1000000,
            searchText: searchText.trim(),
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            console.log("data", data)
            if (status === 200) {
                for (let index = 0; index < data?.ListSaleReport?.length; index++) {
                    let dataObj = {
                        BillNo: data?.ListSaleReport[index].BillNo,
                        Name: data?.ListSaleReport[index].Name,
                        ContactNo: data?.ListSaleReport[index].ContactNo,
                        TotalQty: data?.ListSaleReport[index].TotalQty,
                        BillDate: dateFormat(data?.ListSaleReport[index].BillDate.split("T")[0],
                            "dd/MM/yyyy"),
                        Discount: Math.round(data?.ListSaleReport[index].Discount)?.toFixed(2),
                        CGSTAmount: Math.round(data?.ListSaleReport[index]?.CGSTAmount)?.toFixed(2),
                        SGSTAmount: Math.round(data?.ListSaleReport[index]?.SGSTAmount)?.toFixed(2),
                        IGSTAmount: Math.round(data?.ListSaleReport[index]?.IGSTAmount)?.toFixed(2),
                        NetAmount: Math.round(data?.ListSaleReport[index]?.NetAmount)?.toFixed(2),
                    }
                    excelSheetData.push(dataObj)
                }
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setscreeLoad(false)
            var fields = Object.keys(excelSheetData[0]);
            var sheetData = excelSheetData.map(function (row) {
                return fields.map(function (fieldName) {
                    return row[fieldName];
                });
            });
            sheetData.unshift(header);
            exportDataExcelSales(sheetData, mainTitle, widthObj, extention, data?.TotalNetAmount, data?.TotalCGST, data?.TotalSGST, data?.TotalIGST, data?.TotalDiscount)
        })
    }

    document.title = `Sales | ${localStorage.getItem("ShopName")}`

    return (
        <div className="page-content">
            <div className="container-fluid">
                {screeLoad && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <SalesGSTReportTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            customPageSize={10}
                            isLoading={isLoading}
                            className="custom-header-css"
                            pagePerSize={pagePerSize}
                            TotalBillAmount={TotalBillAmount}
                            TotalRecords={TotalRecords}
                            TotalGSTAmt={TotalGSTAmt}
                            financeAmountShowHide={financeAmountShowHide}
                            TotalAdvance={TotalAdvance}
                            setdate={setdate}
                            date={date}
                            writeDataToExcel={writeDataToExcel}
                            TotalCGSTAmt={TotalCGSTAmt}
                            TotalSGSTAmt={TotalSGSTAmt}
                            TotalIGSTAmt={TotalIGSTAmt}
                        />
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
SalesGstReport.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default SalesGstReport
