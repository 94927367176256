import React, { Fragment, useRef } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button, Label, Input, FormGroup } from "reactstrap"
import { DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import { useEffect } from "react"
import { useState } from "react"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { dateFormat } from "pages/Common"

// Define a default UI for filtering
function GlobalFilter({
  setGlobalFilter,
  isJobListGlobalFilter,
  value,
  setValue,
}) {
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={12}>
        <div className="search-box w-full d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={"Search…"}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

const SalesTable = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  isCreateProduct,
  handleUserClick,
  className,
  pagePerSize,
  isLoading,
  TotalRecords,
  setdateDisble,
  dateDisble,
  date, setdate,
  setPendingBills,
  pendingBills
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      manualPagination: true,
      initialState: {
        pageSize: 20,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useExpanded,
    usePagination
  )
  const [pageIndex, setpageIndex] = useState(1)
  const [prevValue, setPrevValue] = useState();

  const handlesort = column => { }

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const [value, setValue] = React.useState()
  const [isMobile, setisMobile] = useState(window.innerWidth < 768)
  const [isIpad, setIsIpad] = useState(window.innerWidth < 924)

  useEffect(() => {
    pagePerSize(pageSize, pageIndex, value)
    setPrevValue(value);
  }, [pageSize, value, pageIndex])

  useEffect(() => {
    if (value !== prevValue) {
      setpageIndex(1); // Reset pageIndex to 1 when value changes
    }
  }, [value]);

  useEffect(() => {
    setpageIndex(1); // Reset pageIndex to 1 when value changes
  }, [pageSize]);

  const handleClick = () => {
    setdateDisble(!dateDisble);
  }

  const flatpickrRef = useRef(null);
  const checkbox = useRef();

  return (
    <Fragment>
      <Row >
        <Col md="9" >
          <Row>
            <Col md={isIpad ? "3" : "2"} className="table-header">
              <select
                className="form-select"
                value={pageSize}
                onChange={onChangeInSelect}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={isIpad ? "4" : "3"} className="table-header">
              {isGlobalFilter && (
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  isJobListGlobalFilter={isJobListGlobalFilter}
                  value={value}
                  setValue={setValue}
                />
              )}
            </Col>
            <Col md={5} className="table-header" >
              <div className="d-flex" >
                <div className="form-control " onClick={() => handleClick()} style={{ width: "40px", borderRight: "none", backgroundColor: "#EFF2F7", display: "flex", alignItems: "center", cursor: "pointer" }}>
                  {/* <FormGroup check> */}
                  <input
                    id="checkbox"
                    type="checkbox"
                    style={{ width: "15px", height: "15px", cursor: "pointer" }}
                    // value={dateDisble}
                    checked={!dateDisble}
                    onChange={e => setdateDisble(e.target.checked)}
                    ref={checkbox}
                  />
                  {/* </FormGroup> */}
                </div>
                <Flatpickr
                  ref={flatpickrRef}
                  disabled={dateDisble}
                  className="form-control d-block"
                  placeholder="dd M,yyyy"
                  options={{
                    mode: "range",
                    dateFormat: "d-m-Y",
                    minDate : dateFormat((localStorage.getItem("FinancialYearFrom")), "dd-MM-yyyy"),
                    maxDate : dateFormat((localStorage.getItem("FinancialYearTo")), "dd-MM-yyyy"),
                  }}
                  value={date}
                  onChange={(e) => { setdate(e) }}

                />
                <i
                  className="fas fa-calendar-alt calander-icon"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    flatpickrRef.current.flatpickr.open();
                  }}
                ></i>
              </div>
            </Col>
            <Col md={2} className="mt-2">

              <FormGroup check>
                <Input
                  id="checkbox3"
                  type="checkbox"
                  defaultChecked={pendingBills}
                  onChange={e => setPendingBills(e.target.checked)}
                />
                <Label for="checkbox3" style={{ marginTop: "1px" }} check>
                  Pending  Bills
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md="3" style={{ textAlign: isMobile ? "start" : "end" }}
        >
          <Row>
            <Col md="12" className="table-header">

              {isCreateProduct && (
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="primary"
                    className="btn  mb-2 "
                    onClick={handleUserClick}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Sale
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    onClick={() => {
                      handlesort(column)
                    }}
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        width: column.width,
                      },
                    })}
                  >
                    <div className={`mb-0`}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {page.length ? (
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map(cell => {
                        return (
                          <td
                            key={cell.id}
                            className="text-nowrap"
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.minWidth,
                                maxWidth: cell.maxWidth,
                                width: cell.width,
                              },
                            })}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          ) : (
            <tbody
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
              }}
            >
              <tr>
                <td colSpan={headerGroups[0].headers.length}>
                  {isLoading ? "Loading..." : "No Record Found"}
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => setpageIndex(pageIndex - 1)}
              disabled={pageIndex == 1}
            >
              {"<<"}
            </Button>
            {/* <Button
              color="primary"
              onClick={previousPage}
              // disabled={!canPreviousPage}
            >
              {"<"}
            </Button> */}
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page
          <strong style={{ marginLeft: "5px" }}>
            {TotalRecords == 0 ? 1 : pageIndex} of {TotalRecords == 0 ? 0 : Math.ceil(TotalRecords / pageSize)}
          </strong> | Total <strong>{TotalRecords}</strong>
        </Col>
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            {/* <Button color="primary" onClick={nextPage} disabled={!canNextPage}> */}
            {/* <Button color="primary" onClick={pageIndex}>
              {">"}
            </Button> */}
            <Button
              color="primary"
              onClick={() => setpageIndex(pageIndex + 1)}
              disabled={pageIndex == Math.ceil(TotalRecords / pageSize)}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment >

  )
}

SalesTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default SalesTable
