import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Card, CardBody, Col, Row, Input, FormFeedback, Button } from 'reactstrap'
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from 'react-router-dom'
import { dateFormat, getCurrentFinancialYear } from 'pages/Common'
import useApi from 'common/ApiMiddlewere'
import * as Yup from "yup"
import { toast } from 'react-hot-toast'
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import AccountLedgerReportTable from './AccountLedgerReportTable'
import { useFormik } from 'formik'
import { IncomeExpenseExportDataExcel } from 'pages/ExportData'

const AccountLedgerReport = () => {

    document.title = `Account Ledger | ${localStorage.getItem("ShopName")}`
    let { pathname } = useLocation()
    const { postApi, getApi } = useApi()
    const flatpickrRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadiButtonng, setIsButtonLoading] = useState(false)
    const [isMobile, setisMobile] = useState(window.innerWidth < 520)
    const [totalCrAmount, setTotalCrAmount] = useState()
    const [totalDrAmount, setTotalDrAmount] = useState()
    const [accountLedgerData, setAccountLedgerData] = useState([])
    const [date, setdate] = useState([new Date(), new Date()])
    const [accountGroupsData, setAccountGroupsData] = useState([])
    const [openingBalance, setOpeningBalance] = useState(0)
    const [closingBalance, setClosingBalance] = useState(0)
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 600) {
            setVisible(true)
        }
        else if (scrolled <= 600) {
            setVisible(false)
        }
    };

    useEffect(() => {
        const startYear = localStorage.getItem("FinancialYearFrom")
        const endYear = localStorage.getItem("FinancialYearTo")
        const SelectedFinancialYear = startYear?.split("-")[0] + "-" + (endYear?.split("-")[0])
        if (SelectedFinancialYear == getCurrentFinancialYear()) {
            setdate([new Date(), new Date()])
        } else {
            let Date1 = [`01-04-${SelectedFinancialYear?.split('-')[0]}`, `31-03-${SelectedFinancialYear?.split('-')[1]}`]
            const convertedDates = Date1.map(dateStr => {
                const [day, month, year] = dateStr.split('-');
                const dateObj = new Date(year, month - 1, day);
                return dateObj
            });
            setdate(convertedDates)
        }
    }, [localStorage.getItem("FinancialYearDate")])


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    const validation = useFormik({
        enableReinitialize: false,
        initialValues: {
            accountId: 0,
        },
        validationSchema: Yup.object({
            accountId: Yup.number().min(1, "The account is required"),
        }),
        onSubmit: values => {
            if (values) {
                if (date[1] == undefined) {
                    toast("Please Select date", {
                        icon: "⚠️",
                        style: {
                            fontSize: "15px",
                        },
                    })
                } else {
                    setIsButtonLoading(true)
                    postApi("Report/account-ledger", {
                        accountId: parseInt(values.accountId),
                        fromDate: dateFormat(date[0], "yyyy-MM-dd"),
                        toDate: dateFormat(date[1], "yyyy-MM-dd"),
                    }).then(({ data, status }) => {
                        if (status === 200) {
                            setAccountLedgerData(data?.AccountLedgerDrCrData)
                            setTotalCrAmount(data?.TotalCrAmount)
                            setTotalDrAmount(data?.TotalDrAmount)
                            setOpeningBalance(data?.DrAmount - data?.CrAmount)
                            setClosingBalance((data?.DrAmount - data?.CrAmount) + data?.TotalDrAmount - data?.TotalCrAmount)
                        } else {
                            toast.error(data, {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                        }
                        setIsButtonLoading(false)
                    })
                }
            }
        },
    })

    useEffect(() => {
        getApi(`Accounts/accounts-lookup?accountGroup=${0}`).then(({ data, status }) => {
            if (status === 200) {
                setAccountGroupsData(data)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }, [])

    function writeDataToExcel() {
        setIsLoading(true)
        let header = ["Date", "Ref No.", "Account Name", "Details", "Amount", '', "Date", "Ref No.", "Account Name", "Details", "Amount"];
        const mainTitle1 = "Dr Details";
        const mainTitle2 = "Cr Details";
        const widthObj = [{
            Key: "A",
            Value: 12,
        },
        {
            Key: "B",
            Value: 12,
        },
        {
            Key: "C",
            Value: 20,
        },
        {
            Key: "D",
            Value: 20,
        },
        {
            Key: "E",
            Value: 12,
        },
        {
            Key: "F",
            Value: 10,
        },
        {
            Key: "G",
            Value: 12,
        },
        {
            Key: "H",
            Value: 12,
        },
        {
            Key: "I",
            Value: 20,
        },
        {
            Key: "J",
            Value: 20,
        },
        {
            Key: "K",
            Value: 12,
        },
        ]
        const extention = "Account_Ledger.xlsx"
        let excelSheetData = []
        for (let index = 0; index < accountLedgerData.length; index++) {
            let dataObj = {
                Date: accountLedgerData[index].Date == "0001-01-01T00:00:00" ? "" : dateFormat(accountLedgerData[index].Date.split("T")[0],
                    "dd/MM/yyyy"),
                RefNo: accountLedgerData[index].RefNo,
                CrAccountName: accountLedgerData[index].CrAccountName,
                Remarks: accountLedgerData[index].Remarks,
                Amount: accountLedgerData[index].Date == "0001-01-01T00:00:00" ? "" : Math.round(accountLedgerData[index].Amount)?.toFixed(2),
                '': '',
                Date2: accountLedgerData[index].Date2 == "0001-01-01T00:00:00" ? "" : dateFormat(accountLedgerData[index].Date2.split("T")[0],
                    "dd/MM/yyyy"),
                RefNo2: accountLedgerData[index].RefNo2,
                DrAccountName: accountLedgerData[index].DrAccountName,
                Remarks2: accountLedgerData[index].Remarks2,
                Amount2: accountLedgerData[index].Date2 == "0001-01-01T00:00:00" ? "" : Math.round(accountLedgerData[index].Amount2)?.toFixed(2),
            }
            excelSheetData.push(dataObj)
        }
        var fields = Object.keys(excelSheetData[0]);
        var sheetData = excelSheetData.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName];
            });
        });
        setIsLoading(false)
        sheetData.unshift(header);
        IncomeExpenseExportDataExcel(sheetData, mainTitle1, mainTitle2, widthObj, extention, totalDrAmount, totalCrAmount)
    }

    // header & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Date",
                accessor: "Date",
                width: "5%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.Amount > 0 ?
                            <>
                                {row.original.Date == "0001-01-01T00:00:00" ? ""
                                    : dateFormat(
                                        row.original.Date.split("T")[0],
                                        "dd/MM/yyyy"
                                    )}
                            </>
                            : ""}

                    </p>
                ),
            },
            {
                Header: "Ref No.",
                accessor: "RefNo",
                width: "5%",
            },
            {
                Header: "Acc. Name",
                accessor: "CrAccountName",
                width: "6%",
                Cell: ({ row }) => (
                    <p
                        className=" mb-0 text-wrap"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`${row.original.CrAccountName}`}
                    >
                        {row.original.Amount > 0 ?
                            <>
                                {row.original.CrAccountName == '' ? "" : row.original.CrAccountName}
                            </>
                            : ""}
                    </p>
                ),
            },
            {
                Header: "Details",
                accessor: "Remarks",
                width: "6px",
                Cell: ({ row }) => (
                    <p
                        className=" mb-0 text-wrap"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`${row.original.Remarks == null ? '' : row.original.Remarks}`}
                    >
                        {row.original.Remarks == ""
                            ? "N/A"
                            : row.original.Remarks}
                    </p>
                ),
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Amount
                    </div>
                ),
                accessor: "Amount",
                textAline: "end",
                borderRight: "1px solid #959595b0",
                Cell: ({ row }) => {
                    return (
                        <>
                            <div style={{
                                display: "flex",
                                alignItems: "end",
                                justifyContent: "end",
                                textAlign: "end"
                            }}
                            >
                                {row.original.Amount > 0 ?
                                    <>
                                        <i className="mdi mdi-currency-inr"></i>
                                        {new Intl.NumberFormat('en-IN', {
                                            currency: 'INR',
                                            minimumFractionDigits: 2,
                                        }).format(Math.round(row?.original?.Amount) || 0)}</>
                                    : ""}

                            </div>
                        </>

                    )
                },
            },
            {
                Header: "Date",
                accessor: "Date2",
                width: "5%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row?.original?.Amount2 > 0 ? (
                            <>
                                {row.original.DrAccountName == ""
                                    ? ""
                                    : dateFormat(
                                        row.original.Date2?.split("T")[0],
                                        "dd/MM/yyyy"
                                    )}
                            </>
                        ) : ""}
                    </p>

                ),
            },
            {
                Header: "Ref No.",
                accessor: "RefNo2",
                width: "5%",
            },
            {
                Header: "Acc. Name",
                accessor: "DrAccountName",
                width: "6%",
                Cell: ({ row }) => (
                    <p
                        className=" mb-0 text-wrap"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`${row.original.DrAccountName}`}
                    >
                        {row?.original?.Amount2 > 0 ? (
                            <>
                                {row.original.DrAccountName == ""
                                    ? ""
                                    : row.original.DrAccountName}
                            </>
                        ) : ""}
                    </p>
                ),
            },
            {
                Header: "Details",
                accessor: "Remarks2",
                width: "5px",
                Cell: ({ row }) => (
                    <p
                        className=" mb-0 text-wrap"
                        // style={{width:"120px"}}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`${row.original.Remarks2 == null ?
                            '' : row.original.Remarks2}`}

                    >
                        {row.original.Remarks2 == ""
                            ? "N/A"
                            : row.original.Remarks2}
                    </p>
                ),
            },
            {
                Header: "Amount",
                accessor: "Amount2",
                textAline: "end",
                // width: "13%",
                Cell: ({ row }) => {
                    return (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "end",
                                justifyContent: "end",
                                textAlign: "end"
                            }}
                        >
                            {
                                row?.original?.Amount2 > 0 ? (
                                    <>
                                        <i className="mdi mdi-currency-inr"></i><>
                                            {new Intl.NumberFormat('en-IN', {
                                                currency: 'INR',
                                                minimumFractionDigits: 2,
                                            }).format(Math.round(row?.original?.Amount2) || 0)}</>
                                    </>
                                ) : ("")
                            }
                        </div>
                    )
                },
            },
        ],
        []
    )

    return (
        <div className="page-content">
            <div className="container-fluid">
                {isLoading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={7} className='mt-4'>
                                <Row>
                                    <Col md={4} className='mb-2 pe-0'>
                                        <label
                                            htmlFor="name"
                                            className="required form-label"
                                        >
                                            Accounts
                                        </label>
                                        <Input
                                            type="select"
                                            id="validationCustom02"
                                            className="form-control"
                                            name="accountId"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.accountId}
                                            invalid={
                                                validation.touched.accountId &&
                                                    validation.errors.accountId
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <option value={0}>Select...</option>
                                            {accountGroupsData.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.Id}>
                                                        {item.Name}
                                                    </option>
                                                )
                                            })}
                                        </Input>
                                        {validation.touched.accountId &&
                                            validation.errors.accountId ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.accountId}
                                            </FormFeedback>
                                        ) : null}
                                    </Col>
                                    <Col md={5} className="table-header pe-0" style={{ marginTop: "22px" }} >
                                        <div className=" d-flex">
                                            <Flatpickr
                                                ref={flatpickrRef}
                                                className="form-control d-block"
                                                placeholder="dd-MM-yyyy"
                                                value={date}
                                                onChange={(e) => { setdate(e) }}
                                                options={{
                                                    mode: "range",
                                                    dateFormat: "d-m-Y",
                                                    // maxDate: localStorage.getItem("FinancialYearTo"),
                                                    // minDate: localStorage.getItem("FinancialYearFrom"),
                                                    maxDate: dateFormat(localStorage.getItem("FinancialYearTo"), "dd-MM-yyyy"),
                                                    minDate: dateFormat(localStorage.getItem("FinancialYearFrom"), "dd-MM-yyyy"),
                                                }}
                                            />
                                            <i
                                                className="fas fa-calendar-alt calander-icon"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    flatpickrRef.current.flatpickr.open();
                                                }}
                                            ></i>
                                        </div>
                                    </Col>
                                    <Col md={1} className="table-header mb-2 pe-0" style={{ marginTop: "22px" }} >
                                        <div className={`${isMobile ? 'serchButton' : 'serchButtonMargin'} mb-2 `}>
                                            {isLoadiButtonng ? (
                                                <button
                                                    title='Search'
                                                    type="button" className="btn btn-primary">
                                                    <i className="bx bx-hourglass bx-spin font-size-13 align-middle"></i>
                                                    {/* Loading */}
                                                </button>
                                            ) : (
                                                <Button
                                                    title='Search'
                                                    className="btn btn-primary"
                                                    color="primary"
                                                    type="button"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        validation.handleSubmit()
                                                        return false
                                                    }}
                                                >
                                                    <i className="bx bx-search-alt font-size-16 align-middle"></i>
                                                    {/* Search */}
                                                </Button>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md={1} className="table-header mb-2 pe-0" style={{ marginTop: "22px" }} >
                                        <div className={`${isMobile ? 'serchButton' : 'serchButtonMargin'} mb-2 `}>
                                            {accountLedgerData.length > 0 ? (
                                                <Button
                                                    title='Export Excel'
                                                    className="btn btn-excel"
                                                    // color="primary"
                                                    type="button"
                                                    onClick={writeDataToExcel}
                                                >
                                                    {/* Export */}
                                                    <i className="far fa-file-excel font-size-16"></i>
                                                </Button>) :
                                                (<Button
                                                    title='Export Excel'
                                                    className="btn btn-excel"
                                                    // color="primary"
                                                    type="button"
                                                    style={{ cursor: "not-allowed" }}
                                                >
                                                    {/* Export */}
                                                    <i className="far fa-file-excel font-size-16"></i>
                                                </Button>)
                                            }

                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={5} className="table-header mb-2">
                                <Row>
                                    <Col md={6} style={{
                                        textAlign: "start"
                                    }}>
                                        <div style={{ fontSize: "12.5px" }}> From Period</div>
                                        <div
                                            // className={`${date[1] ? "" : "visibility"}`} 
                                            style={{ fontSize: "17px", color: "blue" }}>
                                            <>
                                                {localStorage.getItem("FinancialYearDate")?.split(" to ")[0]}
                                            </>
                                        </div>
                                        <div style={{ fontSize: "12.5px" }}>To Period</div>
                                        <div style={{ fontSize: "17px", color: "blue" }}>
                                            <>
                                                {date[1] ? dateFormat(
                                                    date[1],
                                                    "dd-MM-yyyy"
                                                ) : ''}
                                            </>
                                        </div>
                                    </Col>
                                    <Col md={6} style={{ textAlign: isMobile ? "start" : "end" }}>
                                        <div style={{ fontSize: "12.5px" }}>Opening Balance</div>
                                        <div style={{ fontSize: "17px", color: "green", wordSpacing: "-5px" }}>    <>
                                            <i className="mdi mdi-currency-inr"></i>
                                            {openingBalance > 0 || openingBalance == 0 ? <> {new Intl.NumberFormat('en-IN', {
                                                currency: 'INR',
                                                minimumFractionDigits: 2,
                                            }).format(Math.round(openingBalance) || 0)}  <span style={{ marginLeft: "4px" }}>{!openingBalance == 0 && "Cr"}</span> </> : <> {new Intl.NumberFormat('en-IN', {
                                                currency: 'INR',
                                                minimumFractionDigits: 2,
                                            }).format(Math.round(openingBalance) || 0)} <span style={{ marginLeft: "4px" }}> Dr</span> </>}
                                        </></div>
                                        <div style={{ fontSize: "12.5px" }}>Closing Balance</div>
                                        <div style={{ fontSize: "17px", color: "red", wordSpacing: "-5px" }}> <>
                                            <i className="mdi mdi-currency-inr"></i>
                                            {closingBalance > 0 || closingBalance == 0 ? <> {new Intl.NumberFormat('en-IN', {
                                                currency: 'INR',
                                                minimumFractionDigits: 2,
                                            }).format(Math.round(closingBalance) || 0)} <span style={{ marginLeft: "4px" }}>{!closingBalance == 0 && "Dr"}</span> </> : <> {new Intl.NumberFormat('en-IN', {
                                                currency: 'INR',
                                                minimumFractionDigits: 2,
                                            }).format(Math.round(closingBalance) || 0)}<span style={{ marginLeft: "4px" }}> Cr</span> </>}
                                        </></div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <AccountLedgerReportTable
                                    columns={columns}
                                    data={accountLedgerData}
                                    isLoading={isLoading}
                                    totalCrAmount={totalCrAmount}
                                    totalDrAmount={totalDrAmount}
                                />
                            </Col>
                        </Row>
                        <div onClick={scrollToTop} style={{ position: "fixed", zIndex: "1", bottom: "45px", left: "97%", display: visible ? 'inline' : 'none' }}>
                            <i className="bx bx-chevron-up font-size-25 align-middle me-1" style={{ border: "2px solid #808080ba", borderRadius: "50%", padding: "5px", fontSize: "30px", color: "#808080ba" }}></i>
                        </div>
                    </CardBody>
                </Card>
            </div >
        </div >
    )
}

export default AccountLedgerReport

