import { DateTime } from "luxon";

export function dateFormat(inputDate, format) {
    /* Date format details.
     * MMMM = November
     * MMM = Nov
     * MM = 11
     * dd = 31
     * yyyy = 2022
     * yy = 22
     */
    // parse the input date
    const date = new Date(inputDate);

    // extract the parts of the date
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // replace the month
    if (format.indexOf("MMMM") > -1) {
        // replace the long month
        const longMonth = date.toLocaleString('default', { month: 'long' });
        format = format.replace("MMMM", longMonth);
    } else if (format.indexOf("MMM") > -1) {
        // replace the short month
        const shortMonth = date.toLocaleString('default', { month: 'short' });
        format = format.replace("MMM", shortMonth);
    } else {
        format = format.replace("MM", month.toString().padStart(2, "0"));
    }

    // replace the year
    if (format.indexOf("yyyy") > -1) {
        format = format.replace("yyyy", year.toString());
    } else if (format.indexOf("yy") > -1) {
        format = format.replace("yy", year.toString().substr(2, 2));
    }

    // replace the day
    format = format.replace("dd", day.toString().padStart(2, "0"));
    return format;
}

export function dateTimeFormat(inputDate, format) {
    /* Date format details.
     * MMMM = November
     * MMM = Nov
     * MM = 11
     * dd = 31
     * yyyy = 2022
     * yy = 22
     * HH = 12
     * mm = 60
     * tt = AM or PM
     */
    // parse the input date
    const date = new Date(inputDate);

    // extract the parts of the date
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    var hour = date.getHours();
    const minute = date.getMinutes();
    const amOrPm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;

    // replace the month
    if (format.indexOf("MMMM") > -1) {
        // replace the long month
        const longMonth = date.toLocaleString('default', { month: 'long' });
        format = format.replace("MMMM", longMonth);
    } else if (format.indexOf("MMM") > -1) {
        // replace the short month
        const shortMonth = date.toLocaleString('default', { month: 'short' });
        format = format.replace("MMM", shortMonth);
    } else {
        format = format.replace("MM", month.toString().padStart(2, "0"));
    }

    // replace the year
    if (format.indexOf("yyyy") > -1) {
        format = format.replace("yyyy", year.toString());
    } else if (format.indexOf("yy") > -1) {
        format = format.replace("yy", year.toString().substr(2, 2));
    }

    // replace the hour
    if (format.indexOf("HH") > -1) {
        format = format.replace("HH", hour.toString());
    }

    // replace the minutes
    if (format.indexOf("mm") > -1) {
        format = format.replace("mm", minute.toString());
    }

    // replace the minutes
    if (format.indexOf("tt") > -1) {
        format = format.replace("tt", amOrPm);
    }

    // replace the day
    format = format.replace("dd", day.toString().padStart(2, "0"));
    return format;
}

export function dateFormat_dr(inputDate, format) {
    /* Date format details.
     * MMMM = November
     * MMM = Nov
     * MM = 11
     * dd = 31
     * yyyy = 2022
     * yy = 22
     */
    // parse the input date
    //const date = new Date(inputDate);
    const [day, month, year] = inputDate.split("-").map(Number);
    const date = new Date(year, month - 1, day); // month is zero-based


    if (format.indexOf("MMMM") > -1) {
        // replace the long month
        const longMonth = date.toLocaleString('default', { month: 'long' });
        format = format.replace("MMMM", longMonth);
    } else if (format.indexOf("MMM") > -1) {
        // replace the short month
        const shortMonth = date.toLocaleString('default', { month: 'short' });
        format = format.replace("MMM", shortMonth);
    } else {
        format = format.replace("MM", month.toString().padStart(2, "0"));
    }

    // replace the year
    if (format.indexOf("yyyy") > -1) {
        format = format.replace("yyyy", year.toString());
    } else if (format.indexOf("yy") > -1) {
        format = format.replace("yy", year.toString().substr(2, 2));
    }

    // replace the day
    format = format.replace("dd", day.toString().padStart(2, "0"));
    return format;
}

export function getCurrentFinancialYear() {
    var fiscalyear = "";
    var today = new Date();
    if ((today.getMonth() + 1) <= 3) {
        fiscalyear = (today.getFullYear() - 1) + "-" + today.getFullYear()
    } else {
        fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1)
    }
    return fiscalyear
}

export function getFinancialYear(date) {
    const givenDate = new Date(date);
    const year = givenDate.getFullYear();
    const month = givenDate.getMonth(); // Month is 0-based

    // Financial year starts from April 1st
    const startOfFinancialYear = new Date(year, 3, 1); // April is 3
    const endOfFinancialYear = new Date(year + 1, 2, 31); // March is 2

    if (givenDate < startOfFinancialYear) {
        return `${year - 1}-${year}`;
    } else {
        return `${year}-${year + 1}`;
    }
}

export function addCurrentTimeToDate(date) {
    const nowInIndia = DateTime.now().setZone("Asia/Kolkata").toFormat('HH:mm:ss');
    return `${date}T${nowInIndia}`;
}

export function getISTCurrentDate() {
    return DateTime.now().setZone("Asia/Kolkata").toFormat('dd-MM-yyyy');
}

export function getISTCurrentDateTime() {
    return DateTime.now().setZone("Asia/Kolkata").toFormat('dd-MM-yyyy HH:mm:ss');
}