import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";
import * as Yup from "yup";
import useApi from "common/ApiMiddlewere";
import toast from "react-hot-toast";

const TransactionSettleModel = ({
    modal,
    toggle,
    saleDetails,
    salesProductList,
}) => {
    const { putApi } = useApi()
    const [formSumitLoading, setformSumitLoading] = useState(false)
    const [paymentMethod, setpaymentMethod] = useState("1")
    const [reciveAmt, setReceiveAmt] = useState(false)

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            amount: saleDetails ? saleDetails.NetAmount : 0,
            discount: saleDetails ? saleDetails.Discount : 0,
            advance: saleDetails ? saleDetails.Advance : 0,
            enterAmount: 0,
        },
        validationSchema: Yup.object({
        }),
        onSubmit: values => {
            if (values.enterAmount == 0) {
                setReceiveAmt(true)
                return false
            }

            if (values.enterAmount > values.amount - values.advance) {
                return false
            }

            setformSumitLoading(true)
            if (values.enterAmount > 0) {
                putApi("Sales/sale-payment", {
                    saleId: saleDetails.Id,
                    amount: parseFloat(values.enterAmount) || 0,
                    paymentMode: parseInt(paymentMethod),
                }).then(({ data, status }) => {
                    if (status == 200) {
                        toast.success("Payment sucessfully", {
                            style: {
                                fontSize: "15px",
                            },
                        })
                        salesProductList()
                        validation.handleReset()
                        toggle()
                    } else {
                        toast.error(data, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                        setformSumitLoading(false)
                    }
                    setformSumitLoading(false)
                })
            }

        },
    })

    const handleEnterAmountChange = value => {
        validation.setFieldValue("enterAmount", value)
    }

    return (
        <div>
            <Modal
                isOpen={modal}
                toggle={toggle}
                centered={true}
            >
                <div className="modal-content">
                    <ModalHeader toggle={toggle} tag="h5">
                        Payment of {saleDetails?.BillNo}
                    </ModalHeader>
                    <ModalBody>
                        <Form
                            onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                            }}
                            onReset={e => {
                                e.preventDefault
                                validation.handleReset()
                                return false
                            }}
                        >
                            <Row>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03">
                                            Total Amount
                                        </Label>
                                        <Input
                                            name="totalAmount"
                                            disabled
                                            placeholder="0"
                                            type="text"
                                            className="form-control-sm"
                                            value={validation.values.amount + validation.values.discount}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03">
                                            Discount
                                        </Label>
                                        <Input
                                            name="Discount"
                                            disabled
                                            placeholder="0"
                                            type="text"
                                            className="form-control-sm"
                                            value={validation.values.discount}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03">
                                            Bill Amount
                                        </Label>
                                        <Input
                                            name="amount"
                                            disabled
                                            placeholder="0"
                                            type="text"
                                            className="form-control-sm"
                                            value={validation.values.amount}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03">
                                            Advance
                                        </Label>
                                        <Input
                                            name="amount"
                                            disabled
                                            placeholder="0"
                                            type="text"
                                            className="form-control-sm"
                                            value={validation.values.advance}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <Label htmlFor="validationCustom03">
                                        Receive Amt.
                                    </Label>
                                    <Input
                                        name="enterAmount"
                                        placeholder="0"
                                        type="number"
                                        className="form-control-sm"
                                        // onChange={validation.handleChange}
                                        onKeyPress={e => {
                                            if (e.key === '-' || e.key === 'e') {
                                                e.preventDefault();
                                            }
                                        }}
                                        onChange={e => {
                                            handleEnterAmountChange(e.target.value)
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.enterAmount}
                                        onFocus={(e) => { e.target.select() }}
                                        invalid={
                                            (validation.values.enterAmount > validation.values.amount - validation.values.advance ? true : false) || reciveAmt
                                        }
                                    />
                                    {(validation.values.enterAmount > validation.values.amount - validation.values.advance ? (
                                        <FormFeedback type="invalid">
                                            Receive Amt. should not be grater than {validation.values.amount - validation.values.advance}
                                        </FormFeedback>
                                    ) : null) ||
                                        (reciveAmt ?
                                            <FormFeedback type="invalid">
                                                Receive Amount is required
                                            </FormFeedback> : null)
                                    }
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03"> Total Receive Amount</Label>
                                        <Input
                                            name="advancedMinusRefund"
                                            disabled
                                            type="number"
                                            placeholder="0"
                                            className="form-control-sm"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={parseFloat(validation.values.advance) + parseFloat(validation.values.enterAmount) || parseFloat(validation.values.advance)}

                                        />

                                        <span style={{ color: "green", fontSize: "12px" }}>
                                            Pending Amount: <span style={{ fontFamily: "monospace", fontSize: "14px", paddingRight: "1px", color: "green" }}>₹</span> {Math.round(validation.values.amount - validation.values.advance - validation.values.enterAmount).toLocaleString('en-IN', { currency: 'INR' }) + ".00"}
                                        </span>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={formSumitLoading ? 9 : 10} className="pe-0">
                                    <div className="mb-2 mt-1">
                                        <div className=" d-flex justify-content-end  gap-2">
                                            <input
                                                type="radio"
                                                value="1"
                                                id="cash"
                                                name="mode"
                                                className="form-check-input"
                                                checked={paymentMethod === "1"}
                                                // onChange={(e) => setpaymentMethod(e.target.checked ? "1" : "2")}
                                                onClick={() => { setpaymentMethod("1") }}
                                            />
                                            <Label className="form-check-label font-size-13" htmlFor="cash">
                                                Cash
                                            </Label>

                                            <input
                                                type="radio"
                                                value="2"
                                                id="bank"
                                                name="mode"
                                                className="form-check-input"
                                                checked={paymentMethod === "2"}
                                                // onChange={(e) => setpaymentMethod(e.target.checked ? "2" : "1")}
                                                onClick={() => { setpaymentMethod("2") }}
                                            />
                                            <Label className="form-check-label font-size-13" htmlFor="bank">
                                                Bank
                                            </Label>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={formSumitLoading ? 3 : 2}>
                                    <div className="d-flex justify-content-end align-items-center gap-2">
                                        {formSumitLoading ? (
                                            <button type="button" className="btn btn-primary btn-sm">
                                                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                                Loading
                                            </button>
                                        ) : (
                                            <Button
                                                color="primary"
                                                className="btn btn-primary btn-sm "
                                                type="submit"
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </div>
                                </Col>
                            </Row>

                        </Form>
                    </ModalBody>
                </div>
            </Modal>
        </div>
    )
}


export default TransactionSettleModel;
